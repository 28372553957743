import React from 'react';
import Navbar1_CSS from './Navbar1.css';
import QT_img from './QT_img.jpg';
import { Link } from 'react-router-dom';
import CV_Dev from './CV_Devashish.pdf';

function Navbar1() {
  return (
    <div className='MainNav'>
      <img src={QT_img} alt='Queens Tower, Imperial College London' className="QT-img" />

      <div className='NavBarTop'>
        <div className='NavBar'>
          <Link to='/'><h2 className='Home'>HOME</h2></Link>
          <Link to='/portfolio'><h1 className='Portfolio'>PORTFOLIO</h1></Link>
          
          <a href="https://devashish-blog.netlify.app/static/media/CV_Devashish.bc6e942e8f67ce2d67a1.pdf" target="_blank" rel="noopener noreferrer">
            <h1 className='CV'>CV</h1>
          </a>

          <Link to='/contact'><h1 className='Contact'pop-out>CONTACT</h1></Link>
        </div>
        <div className='SideBar'></div>
      </div>

      <div className='DeveloperNote'>
        <h3>Developed on <b>React</b></h3>
      </div>
    </div>
  );
}

export default Navbar1;
