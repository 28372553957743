import React, { useState } from 'react';
import './Contact.css';
import emailjs from 'emailjs-com';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    setIsValidEmail(emailRegex.test(email));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const resetForm = () => {
    setFormData({
      name: '',
      email: '',
      message: '',
    });
    setIsSubmitted(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        'service_gqbgg2g',
        'template_zshtizh',
        e.target,
        '8x1WtOaJgwQVtyHVm'
      )
      .then(() => {
        resetForm();
      })
      .catch((error) => {
        console.error('Error sending email:', error);
      });
  };

  return (
    <div>
      <form className='ContactForm' onSubmit={handleSubmit}>
        <h1 className='ConHeader'>GET IN TOUCH</h1>
        <div className='textbox-main'>
          <label className='name1'>Name:</label>
          <input
            type='text'
            required
            className='input-main1'
            name='name'
            value={formData.name}
            onChange={handleInputChange}
          />
        </div>
        <div className='textbox-main'>
          <label className='e-mail'>E-mail:</label>
          <input
            type='text'
            required
            className='input-main2'
            name='email'
            value={formData.email}
            onChange={handleInputChange}
          />
        </div>
        <div className='textbox-main'>
          <label className='message'>Message:</label>
          <textarea
            required
            className='input-main3'
            id='textarea'
            name='message'
            value={formData.message}
            onChange={handleInputChange}
          ></textarea>
        </div>
        {isSubmitted && (
          <h5 className='DisplayText'>Thank you for getting in touch!</h5>
        )}
        <button type='submit' className='submit-button'>
          Submit
        </button>
      </form>
    </div>
  );
}

export default Contact;
