import WC_sim from './media/Port_media/WC_sim.jpeg'
import UROP_AI from './media/Port_media/UROP_AI.jpg'
import ProshteticSoc1 from './media/Port_media/prosheticsSoc1.jpg'
import Racecar from './media/Port_media/Racecar.jpg'
import DiffusionSim from './media/Port_media/DiffusionSim.png'

import GIF_decompression from './media/Port_media/GIF.png'
import Stethoscope from './media/Port_media/Stethoscope.png'

import EE from './media/Port_media/ExtendedEssay.png'
import MathIA from './media/Port_media/MathAI.jpeg'

import PhysicsIA from './media/Port_media/PhysicsIA.png'
import ChemIA from './media/Port_media/ChemIA.png'



const PortfolioObject = [
  {
    index: 1,
    year: "2023",
    img_path: WC_sim,
    heading1: 'Powered Wheelchair Simulator',
    heading2: 'Building a Powered Wheelchair Simulator for children with Cerebral Palsy',
    paragraph: 'Children with Cerebral Palsy often face mobility challenges with powered wheelchairs, affecting their independence. Learning to operate these wheelchairs can be especially difficult and time-consuming for them. In collaboration with The Pace Center, we\'ve developed a powered wheelchair simulator to ease this learning curve. This simulator offers a holistic learning experience, integrating vestibular, visual, and auditory feedback to create a realistic and effective training environment',
    pdfAvailable: true,
    pdfLink: '""',
    gitAvailable: true,
    gitLink: '""'
  },
  {
    index: 2,
    year: "2023",
    img_path: UROP_AI,
    heading1: 'Undergrate Research Project on AI',
    heading2: 'Exploring the use of Aritificial Intelligence in the field of Academia',
    paragraph: 'The unprecedented growth in Artificial Intelligence complemented by advancements in computing has opened up opportunities to deliver teaching content like never before. Personalized learning has been known to outperform traditional learning by a remarkable metric of 2 standard deviations, and the integration of AI with education has the potential to revoulutionarize modern pedagogy. The literature review explores and evaluates numerous use cases of AI and Large Language Models in education.',
    pdfAvailable: true,
    pdfLink: '""',
    gitAvailable: false,
    gitLink: '""'
  },
  {
    index: 3,
    year: "2023",
    img_path: DiffusionSim,
    heading1: 'Diffusion Simulator',
    heading2: 'Coding a 2D Diffusion and Heat transfer simulation on Python',
    paragraph: 'The Diffusion/Heat Transfer simulator on Python can be used to numerically approximate and visualize the flow of mass/heat within a 2 dimensional system. The simulation is initialized with a defined number of cells which can be assigned concentration or temperature values. Neumman and Direchlit boundry conditions can then be specified for each edge, and the simulation can be run over a pre-selected time interval. ',
    pdfAvailable: false,
    pdfLink: '""',
    gitAvailable: true,
    gitLink: '""'
  },
  {
    index: 4,
    year: "2023",
    img_path: ProshteticSoc1,
    heading1: 'Prosthetic Myoelectric Arm',
    heading2: 'Engineering a Myoelectric Arm Prototype for Amputees',
    paragraph: 'Imperial College Prosthetics Society worked on a variety of projects to design prosthetic arms and legs for amputees. As part of the Myoelectric Arm team, we were tasked with designing a Myoelectric arm which harnessed electrical signals from the user\'s residual muscles to create movement. I was heavily involved with Signal Processing and designing the electronics for the Myoelectric arm to filter, and amplify the biosignal.',
    pdfAvailable: true,
    pdfLink: '""',
    gitAvailable: false,
    gitLink: '""'
  },
  {
    index: 5,
    year: "2023",
    img_path: Racecar,
    heading1: 'Modifying a Toy Racecar',
    heading2: '""',
    paragraph: 'ILorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac dolor ac velit semper aliquam. Vivamus et gravida magna, pharetra dapibus erat. Donec in est dui. Sed at odio dapibus dui rhoncus interdum eu at tortor. Donec lacus mauris, accumsan at tincidunt id, bibendum in dui. Donec molestie lacus sit amet libero lobortis tempus. Ut laoreet sed elit eget lobortis. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    pdfAvailable: false,
    pdfLink: '""',
    gitAvailable: true,
    gitLink: '""'
  },
  {
    index: 6,
    year: "2022",
    img_path: GIF_decompression,
    heading1: 'GIF Decompression Algorithm',
    heading2: 'Developing a LZW based GIF Decompression Algorithm',
    paragraph: 'Compression algorithms are essential to reduce the size of files while preserving key information, making them suitable for transmission and storage. The project involved designing a decompression algorithm for 87a GIF files using Lempel-Ziv-Welch (LZW) decompression. The decompression algorithm was implemented on Python and tested against a variety of sample GIF files.',
    pdfAvailable: false,
    pdfLink: '""',
    gitAvailable: true,
    gitLink: '""'
  },
  {
    index: 7,
    year: "2022",
    img_path: Stethoscope,
    heading1: 'Medical Stethoscope ',
    heading2: 'Designing a Functional Stethoscope using Filters and Amplifiers',
    paragraph: 'A stethoscope is a medical instrument used by healthcare professionals to listen to internal sounds within a patient\'s body. Stethoscopes have played a ubiqioutous role in primary care and diagnosis, and this project aimed to leverage fundamental electronic components to design amplifiers and filters to generate a low noise output signal with a high resoulution. The design was extensively tested using fast fourier transforms, and physical testing with a human heartbeat simulator.  ',
    pdfAvailable: true,
    pdfLink: '""',
    gitAvailable: false,
    gitLink: '""',
    altiumAvailable: true,
    altiumLink: ''
  },
  {
    index: 8,
    year: "2021",
    img_path: EE,
    heading1: 'Dynamic Viscosity of Air',
    heading2: 'Investigating the Dynamic Viscosity of Air using the Decay in Bubble Radius',
    paragraph: 'ILorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac dolor ac velit semper aliquam. Vivamus et gravida magna, pharetra dapibus erat. Donec in est dui. Sed at odio dapibus dui rhoncus interdum eu at tortor. Donec lacus mauris, accumsan at tincidunt id, bibendum in dui. Donec molestie lacus sit amet libero lobortis tempus. Ut laoreet sed elit eget lobortis. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    pdfAvailable: true,
    pdfLink: '""',
    gitAvailable: false,
    gitLink: '""'
  },
  {
    index: 9,
    year: "2021",
    img_path: MathIA,
    heading1: 'Optimizing Packaging Material',
    heading2: 'Modelling a Kinder Egg and Optimizing the SA:V ratio to reduce waste',
    paragraph: 'ILorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac dolor ac velit semper aliquam. Vivamus et gravida magna, pharetra dapibus erat. Donec in est dui. Sed at odio dapibus dui rhoncus interdum eu at tortor. Donec lacus mauris, accumsan at tincidunt id, bibendum in dui. Donec molestie lacus sit amet libero lobortis tempus. Ut laoreet sed elit eget lobortis. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    pdfAvailable: true,
    pdfLink: '""',
    gitAvailable: false,
    gitLink: '""'
  },
  {
    index: 10,
    year: "2020",
    img_path: PhysicsIA,
    heading1: 'IB Physics IA: Torsional Pendulum',
    heading2: 'Investigating the Relationship between the MoI and the Torsional Constant',
    paragraph: 'ILorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac dolor ac velit semper aliquam. Vivamus et gravida magna, pharetra dapibus erat. Donec in est dui. Sed at odio dapibus dui rhoncus interdum eu at tortor. Donec lacus mauris, accumsan at tincidunt id, bibendum in dui. Donec molestie lacus sit amet libero lobortis tempus. Ut laoreet sed elit eget lobortis. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    pdfAvailable: true,
    pdfLink: '""',
    gitAvailable: false,
    gitLink: '""'
  },
  {
    index: 11,
    year: "2020",
    img_path: ChemIA,
    heading1: 'IB Chemistry IA: Thermal Stability',
    heading2: 'Investigating the Thermal Stability of Carbonates and Bicarbonates',
    paragraph: 'ILorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac dolor ac velit semper aliquam. Vivamus et gravida magna, pharetra dapibus erat. Donec in est dui. Sed at odio dapibus dui rhoncus interdum eu at tortor. Donec lacus mauris, accumsan at tincidunt id, bibendum in dui. Donec molestie lacus sit amet libero lobortis tempus. Ut laoreet sed elit eget lobortis. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    pdfAvailable: true,
    pdfLink: '""',
    gitAvailable: false,
    gitLink: '""'
  }
]
  
  export default PortfolioObject;   