import './Dropdown.css';
import React, { useState } from 'react';

function Dropdown({ sendStateToParent }) {
  const [isOpen, setIsOpen] = useState(false);
  const [displayProject, setDisplayProject] = useState('2023')
    
  const handleClick = (event) => {
    const selectedYear = event.target.id;
    sendStateToParent(selectedYear);
    setDisplayProject(selectedYear);
    setIsOpen(false); // Close the dropdown after selection
  };

  return (
    <div className="dropdown">
      <button onClick={() => setIsOpen(!isOpen)} className="dropbtn">
        <h1>Year: {displayProject} {sendStateToParent} <span className="triangle">&#9660;</span></h1>
      </button>
      {isOpen && (
        <div id="myDropdown" className="dropdown-content">
          <button className="button2023" id="2023" onClick={handleClick}>
            2023
          </button>
          <button className="button2022" id="2022" onClick={handleClick}>
            2022
          </button>
          <button className="button2021" id="2021" onClick={handleClick}>
            2021
          </button>
          <button className="button2020" id="2020" onClick={handleClick}>
            2020
          </button>
        </div>
      )}
    </div>
  );
}

export default Dropdown;
