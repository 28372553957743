import Portfolio from './Portfolio';
import PortfolioObject from './PortfolioObject';
import CompletePorfolio from './CompletePortfolio.css';
import Dropdown from './Dropdown/Dropdown';

import React, { useState } from 'react';

function CompletePortfolio() {  
  const [childState, setChildState] = useState('2023');

  const filteredPortfolio = PortfolioObject.filter((item) => item.year === childState);

  const receiveChildState = (value) => {
    setChildState(value);
  };
  
  return (
    <div>
      <h1 className='PortHeader'>MY PORTFOLIO</h1>
      <Dropdown className='test1' sendStateToParent={receiveChildState} />

      <div className='grid-container'>
        {filteredPortfolio.map((item, index) => (
          <div key={index} className={`Item${index + 1}`}>
            <Portfolio {...item} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default CompletePortfolio;
