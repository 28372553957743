import './Portfolio.css'

function Portfolio(props){
    return (
        <div className='PortfolioMain'>
            <div className="PortfolioComponent">
                <div className='img-container'>
                    <img src = {props.img_path}  className='EE-img'/>
                </div>
                <div className='CompleteSection'>
                    <div className='Heading1'>
                        <h1>{props.heading1}</h1>   
                        {props.pdfAvailable && <h3>[.pdf]</h3>}
                        {props.gitAvailable && <h3>[GitHub]</h3>}
                        {props.altiumAvailable && <h3>[AltiumSchem]</h3>}
                    </div>
                    <h2>{props.heading2}</h2>
                    <p><b>Abstract:</b> {props.paragraph}</p>
                </div>
            </div>
        </div>
    )   
}

export default Portfolio;
