import './App.css';
import Navbar1 from './components/Navbar1';
import MainContent from './components/MainContent';
import CompletePortfolio from './components/CompletePortfolio';
import Contact from './components/Contact/Contact';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Portfolio from './components/Portfolio';

function App() {
  return (
    <Router> 
      <div className="App">
        <div className="HomePage">
          <Navbar1 />
        </div>
        <Switch>
          <Route path="/portfolio">
            <CompletePortfolio />
          </Route>

          <Route path="/contact">
            <Contact />
          </Route>

          <Route exact path="/">
            <MainContent />
          </Route>

        </Switch>
      </div>
    </Router>
  );
}

export default App;
