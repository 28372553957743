import React from 'react';
import MainContent_css from './MainContent.css'
import Dev_img from './Dev_img.png'

function MainContent(){
    return(
        <div>
            <img src = {Dev_img} alt = "Devashish Joshi" className='Dev_img'/>
            <div className='MainPage-Intro'>
                <h1>Devashish Joshi</h1>
                <h5>devashish.joshi21@imperial.ac.uk</h5>
                <p>I am a 2nd Year Biomedical Engineering Student at the department of bioengineering at Imperial College London. 
                    On this website, you can find my projects, notes, and other cool stuff that I have worked on over these years. </p>
            </div>
        </div>
    )
}

export default MainContent